<!--
 * @Description: 首页
 * @Author: luocheng
 * @Date: 2021-09-13 13:55:25
 * @LastEditors: 朱廷果 1028509503@qq.com
 * @LastEditTime: 2023-08-18 10:57:59
-->
<template>
	<div class="app-box">
    <div v-if="systemUUid">
      <div class="app-center" v-if="componentDatas">
        <Parser :customComponentData="componentDatas"></Parser>
      </div>
    </div>
    <div v-else>
      <template v-if="validData && Array.isArray(validData) && validData.length">
        <div
        class="container"
        v-for="(item, index) in validData"
        :key="index"
        :style="item.listData.length && item.is_show? '':'display:none'"
      >
        <div class="header">
          <h4 class="title">{{ item.shortcut_name }}</h4>
          <!-- 取消跳转工单中心 -->
        </div>
        <div
          class="content"
          :class="{
            'grid-content': validData && Array.isArray(validData) && validData.length
          }"
        >
          <template v-if="item.listData && Array.isArray(item.listData) && item.listData.length">
            <!-- eslint-disable -->
            <div
              v-for="(items, index) in item.listData"
              :key="index"
              class="content-item-box"
              @click="handleAppClick(items)"
            >
              <div class="content-item">
                <div class="content-icon">
                  <!-- 默认 -->
                  <i
                    class="iconfont iconzhuomianyingyong"
                    v-if="!items.shortcut_icon"
                  ></i>
                  <!-- element icon -->
                  <i
                    v-if="items.shortcut_icon.indexOf('el-icon-') === 0"
                    :class="[items.shortcut_icon]"
                  ></i>
                  <svg
                    aria-hidden="true"
                    class="iconfont-svg"
                    v-else
                  >
                    <use :xlink:href="'#' + items.shortcut_icon" />
                  </svg>
                </div>
                <div class="content-text">
                  <p class="item-title">{{ items.shortcut_name }}</p>
                  <p
                    class="item-desc"
                    v-if="items.shortcut_desc"
                  >
                    {{ items.shortcut_desc }}
                  </p>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
      </template>
      <el-empty
        description="暂无数据"
        v-else
        :image-size="80"
      ></el-empty>
    </div>
	</div>
</template>

<script>
import { dataInterface } from '@/apis/data';
import { infoList, getMenuOriginData } from './config';
import { Empty } from 'element-ui';
import { mapState } from 'vuex';
import { initParams,isJSONStr ,deepCopy,judgingArchiAPermission, judgingUILogic  } from '@/utils/tools';
import Parser from '@/views/appEntry/components/appCenter/Parser'
export default {
	name: 'myApp',
	components: {
		'el-empty': Empty,
    Parser
	},
	props: {
		pageUUID: {
			type: String,
			required: false,
			default: ''
		},
		componentList: {
			default: null
		}
	},
  provide() {
    return {
      EDITOR_pageUUID: this.systemUUid
    }
  },
	data() {
		return {
			appConfig: [],
			infoList,
			loading: false,
      componentDatas: null
		};
	},
	computed: {
		...mapState(['targetArchi', 'componentData','systemConfig']),
		validData() {
			let newArray = deepCopy(this.appConfig);
			newArray = newArray.filter((item) => item.listData && Array.isArray(item.listData) && item.listData.length);
			newArray = newArray.filter((item) => {
				item.listData = item.listData.filter((element) => {
					if (this.judgingArchiAPermission(element.archi_limit, element.permissions) && this.judgingUILogic(element.UILogic, this.componentList || this.componentData)) {
						return true;
					}
				});
				if (item.listData && item.listData.length > 0) {
					return true;
				}
			});
			return newArray;
		},
    systemUUid() {
			return this.systemConfig?.app_center_uuid ;
		},
	},
	watch: {
		targetArchi: {
			handler(newV, oldV) {
				if (!newV || !oldV) return;
				this.getShortcuts();
			},
			deep: true,
			immediate: true
		}
	},
	created() {
		this.getShortcuts();
    if(this.systemUUid){
      console.log(1111)
      this.getViewData()
    }
	},
  mounted(){

  },
	methods: {
		judgingArchiAPermission,
		judgingUILogic,
    getViewData() {
			dataInterface({
				__method_name__: 'dataList',
				page_uuid:this.systemUUid,
				object_uuid: 'a4f016d6-c602-4492-8874-f088c3c0b3b9',
				view_uuid: 'view61b951c6a8186',
				transcode: 0
			}).then((res) => {
        console.log(res)
        const { page_data } = res?.data?.data?.[0] || {};
        const { componentData } = isJSONStr(page_data) ? JSON.parse(page_data) : page_data
        console.log(componentData)
        this.componentDatas = componentData;
      }).catch(() => {
        this.loading = false;
      });
		},
		/**
		 * @desc: 点击快捷方式(我的应用)
		 * @param {Object} item
		 */
		handleAppClick(item) {
			/* eslint-disable */
			const {
				shortcut_path,
				app_id,
				shortcut_type = 1, // 快捷方式类型 1 链接(页面) 2 组合 3 应用
				params = [],
        gopath = false  // 直接进入路由（常用应用）
			} = item;
      if(gopath){
        this.$router.push({
					path:shortcut_path,
					query: {
						pageUUID: item.pageUUID??""
					}
				});
        return
      }
      let path = shortcut_path;
			let originData = null;
			// 获取当前应用
			const appList = JSON.parse(sessionStorage.getItem('appList'));
			const currentApp = appList.find(ele => +ele.id === +app_id);
			if (!currentApp) {
				this.$message.error('系统错误【未查询到关联应用】！');
				return;
			}
			this.$store.commit('setCurrentApp', currentApp);
			if (shortcut_type === 2) {
				// 组合 获取分组下第一个可跳转菜单
				const groupData = this.getGroupData(shortcut_path, currentApp?.app_orign_data || []);
				const groupPath = this.getGroupIndexPath(groupData?.children_list);
				if (!groupData || !groupPath) {
					this.$message.error('无效快捷菜单！');
					return;
				}
				path = groupPath?.menu_path;
				originData = groupPath;
			} else if (shortcut_type === 3) {
				// 应用 跳转应用下的首页
				const indexObj = this.getIndexPath(currentApp?.app_orign_data || []);
				if (!indexObj) {
					this.$message.error('应用暂未配置有效首页!');
					return;
				}
				originData = indexObj;
				path = indexObj.menu_path;
			} else {
				// 普通路由跳转
				originData = getMenuOriginData(path, currentApp?.app_orign_data);
			}
			if (!path || !originData) {
				this.$message.error('暂未配置目标页面!');
				return;
			}
			if (originData) {
				this.$store.commit('setCurrentMenu', {
					level: originData.level,
					menuData: originData
				});
			}
			if (originData.menu_type === 1) {
				// window.open('https://ltsd-bim.com/pc/dataview/show/20')
				window.open(path.indexOf('http') === 0 ? path : window.origin + path);
				return;
			}
			// 获取参数
			const { param = {}, canPost } = initParams(params);
			if (!canPost) {
				this.$message.error('跳转附加参数错误!');
				return;
			}
			if (+originData.menu_type === 4 && path.indexOf('dataview/show') !== -1) {
				window.open(path.indexOf('http') === 0 ? path : `${window.origin}${path}${this.formatUrl(param)}`);
			} else {
				this.$router.push({
					path,
					query: {
						...param,
						menu_type: originData.menu_type,
						pageUUID: originData.page_uuid
					}
				});
			}
		},
		/**
		 * @description: 格式化URL
		 * @param {Object} obj 配置对象
		 */
		formatUrl(obj) {
			let url = '';
			for (let key in obj) {
				url = `${url}${key}=${obj[key]}&`;
			}
			return url;
		},
		/**
		 * @desc: 获取应用首页
		 * @param {Array} list route list
		 */
		getIndexPath(list = []) {
			for (let i = 0; i < list.length; i++) {
				const { is_index, is_hide, children_list, menu_type } = list[i];
				if (is_index && !is_hide && (+menu_type === 0 || +menu_type === 4)) {
					return list[i];
				}
				if (children_list && children_list.length) {
					const result = this.getIndexPath(children_list);
					if (result) {
						return result;
					}
				}
			}
			return null;
		},
		/**
		 * @desc: 获取分组数据
		 * @param {String} groupPath
		 * @param {Array} routeList
		 */
		getGroupData(groupPath = '', list = []) {
			for (let i = 0; i < list.length; i++) {
				const { menu_type, menu_path, children_list = [] } = list[i];
				if (+menu_type === 2 && menu_path === groupPath) {
					return list[i];
				}
				if (children_list && children_list.length) {
					const result = this.getGroupData(groupPath, children_list);
					if (result) {
						return result;
					}
				}
			}
			return null;
		},
		/**
		 * @desc: 获取分组中第一个可访问路由
		 * @param {Array} list
		 */
		getGroupIndexPath(list = []) {
			for (let i = 0; i < list.length; i++) {
				const { menu_type, children_list = [] } = list[i];
				if (+menu_type === 0 || +menu_type === 4) {
					return list[i];
				}
				if (children_list && children_list.length) {
					const result = this.getGroupIndexPath(children_list);
					if (result) {
						return result;
					}
				}
			}
			return null;
		},
		/**
		 * @desc: 获取当前项目的快捷方式列表
		 */
		getShortcuts() {
			this.loading = true;
			dataInterface({
				__method_name__: 'dataList',
				object_uuid: 'object62b41ff801edf',
				view_uuid: 'view62b42143d9c8b',
				project_id: process.env.VUE_APP_BASE_ID,
				transcode: 0
			})
				.then(res => {
					const arrList = [];
					this.appConfig = res?.data?.data || [];
					// console.log(this.appConfig, '---00000-----');
					// this.appConfig.forEach(ele => {
					//   console.log(ele.shortcut_name, '-----1111-------', ele);
					// })
					this.appConfig.map(el => {
						if (el.is_group == 1) {
							arrList.push({
								...el,
								listData: []
							});
						}
					});
					this.appConfig.map(el => {
						arrList.map(item => {
							if (el.is_group == 0 && el.group_id == item.id) {
								item.listData.push(el);
							}
						});
					});
					this.appConfig = arrList;
          this.getCommonData()
					this.loading = false;
				})
				.catch(err => {
					console.log(err, '----err');
					this.loading = false;
				});
		},
     /**
     * @desc 获取常用功能列表
     */
    getCommonData(){
      // const devToken = getDevToken();
      dataInterface({
        // token: devToken,
        platform: 'PC'
      }, `${process.env.VUE_APP_BASE_URL}api/v4/user/commonFunctions`,'GET')
      .then(res=>{
        if(res.status===200){
          let data = res.data.data
          this.appConfig.forEach(item=>{
            if(item.app_id ===-1){
              let length = item.listData.length
              let pathString = ''
              if(item?.not_show_path?.length){
                for(let i = 0; i <item.not_show_path.length; i++) {
                  pathString +=item.not_show_path[i].menu_path
                }
              }
              data = data.filter(item=> !pathString.includes(item.path))
              for (let index = 0; index < (6 - length); index++) {
                if(data[index] && data[index].infos){
                    item.listData.push({
                      shortcut_name:JSON.parse(data[index]?.infos)?.shortcut_name,
                      shortcut_path:data[index].path,
                      shortcut_icon:JSON.parse(data[index]?.infos)?.shortcut_icon,
                      id:data[index].appId,
                      gopath:true,
                      pageUUID:data[index].pageUUID,
                    })
                }
              }
            }
          })
        }

      })
    },
		/**
		 * @description: 废弃
		 */
		goManageCenter() {
			this.$store.commit('setShowAppCenter', true);
			this.$router.push({
				path: 'work-order',
				query: {
					menu_type: 4
				}
			});
		}
	}
};
</script>

<style lang="less" scoped>
@top: 53px;

.app-box {
	border-radius: 8px;
	padding: 6px 20px 20px 20px;
	box-sizing: border-box;
	overflow: hidden;
	overflow-y: auto;
  width: 100vw;
  height: calc(100vw - @top);
  position: fixed;
  left: 0;
  top: @top;
  z-index: 30001;
  background: #fff;
  :deep(.el-empty) {
    width: 100%;
    height: 100%;
  }
	.container {
		width: 100%;
		padding-top: 20px;
		.header {
			display: flex;
			justify-content: space-between;
			align-items: center;
		}
		.title {
			font-size: 16px;
			font-family: PingFangSC-Medium, PingFang SC;
			color: #2a2f3d;
			letter-spacing: 1px;
		}
		.content {
			padding-top: 12px;
			&.grid-content {
				display: grid;
				grid-template-columns: repeat(5, 20%);
				grid-column-gap: 16px;
				grid-row-gap: 16px;
			}
			.content-item-box {
				.content-item {
					padding: 12px 21px 12px 12px;
					border-radius: 8px;
					border: 1px solid #f2f5fc;
					display: flex;
					align-items: center;
					cursor: pointer;
					.content-icon {
						height: 40px;
						width: 40px;
						line-height: 40px;
						box-sizing: border-box;
						text-align: center;
						font-size: 36px;
						i {
							display: block;
							height: 100%;
						}
						.iconfont-svg {
							display: block;
							height: 100%;
							width: 100%;
						}
					}
					.item-icon-info {
						width: 21px;
						height: 21px;
						padding: 0 8px;
					}
					.content-text {
						box-sizing: border-box;
						flex: 1;
						padding-left: 12px;
						height: 100%;
						overflow: hidden;
						.item-title {
							height: 22px;
							font-size: 14px;
							font-family: PingFangSC-Regular, PingFang SC;
							font-weight: 400;
							color: #272829;
							line-height: 22px;
						}
						.item-desc {
							height: 18px;
							width: 100%;
							box-sizing: border-box;
							box-sizing: border-box;
							font-size: 12px;
							font-family: PingFangSC-Regular, PingFang SC;
							font-weight: 400;
							color: #969799;
							line-height: 18px;
							overflow: hidden;
							text-overflow: ellipsis;
							white-space: nowrap;
						}
						.item-title,
						.item-desc {
							white-space: nowrap;
							text-overflow: ellipsis;
							overflow: hidden;
						}
					}
				}
				.el-empty {
					margin: 0 auto;
					padding: 20px 0;
				}
			}
		}
	}
	.block {
		display: block;
	}
}
@media screen and (max-width: 1600px) {
	.app-box {
		.container {
			.content {
				&.grid-content {
					grid-template-columns: repeat(2, 46%);
				}
			}
		}
	}
}
</style>
